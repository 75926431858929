type StateItem = {
  price: {
    amount: number;
    currency: string;
  },
  percentage: number,
}

type TipsStateTypes = {
  tips: Array<StateItem>,
}

export const tips = {
  namespaced: true,
  state: (): TipsStateTypes => ({
    tips: [
      {
        price: {
          amount: 3,
          currency: 'USD'
        },
        percentage: 15,
      },
      {
        price: {
          amount: 5,
          currency: 'USD'
        },
        percentage: 20,
      },
      {
        price: {
          amount: 10,
          currency: 'USD'
        },
        percentage: 25,
      }
    ],
  }),
  getters: {},
  actions: {}
}
