import { createStore } from 'vuex'
import { modal } from '@/store/modal'
import { chat } from '@/store/chat'
import { user } from '@/store/user'
import { contact } from '@/store/contact'
import { bag } from '@/store/bag'
import { tips } from '@/store/tips'
import { companies } from '@/store/companies'
import { categories } from '@/store/categories'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    modal,
    chat,
    user,
    contact,
    bag,
    tips,
    companies,
    categories
  }
})
