import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from "@/i18n";
import VueMultiselect from 'vue-multiselect'

// styles
import './styles/index.scss'
import 'vue3-emoji-picker/css'
import 'animate.css'
import 'vue3-carousel/dist/carousel.css'
import '@textabledev/langs-flags-list/lang-flags.css'
import 'vue3-toastify/dist/index.css';
import "@/assets/styles/vue-multiselect.scss";
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Vue3Toastify, {
    autoClose: 3000,
  } as ToastContainerOptions)
  .component('vue-multiselect', VueMultiselect)
  .mount('#app')
