export function browserNotify() {
  if (Notification.permission !== 'granted')
    Notification.requestPermission();
  else {
    const notification = new Notification('Mabell', {
      icon: require('../assets/logo.svg'),
      body: 'You have a new message.',
    });
    notification.onclick = function() {
      window.open(window.location.href);
    };
  }
}
